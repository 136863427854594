<template>
  <b-row>
    <b-col class="nopadding" cols="12" xl="4">
      <b-card class="h-100">
        <div class="text-center">
          <img v-if="user.logo" :src="computed_logo(user.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <h6>{{$t('common.choose_another_image')}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage" id="fileControl">
        </div>
        <br>
        <div class="row">
          <div class="col bg-secondary">
          <div class="row">
            <div class="col-7">
              <b-form-group>
                <label for="txt_name">{{$t('common.fullname')}}</label>
                <div v-if="$v.user.name.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.user.name.required">{{$t('common.fullname_required')}}</div>
                </div>
                <b-form-input type="text" id="txt_name" :placeholder="$t('common.placeholder_fullname')"
                v-model.trim="user.name" maxlength="50" ref="txt_name" size="sm"
                v-on:input="$v.user.name.$touch"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-5">
              <b-form-group>
                <label for="ddlGender">{{$t('common.gender')}}</label>
                <div v-if="$v.user.gender.$error" class="pull-right">
                    <div class="text-danger" v-if="!$v.user.gender.required">{{$t('common.gender_required')}}</div>
                </div>
                <b-form-select id="ddlGender" v-model.trim="user.gender" size="sm">
                    <option value="null" disabled>{{$t('common.select_gender')}}</option>
                    <option v-for="d in ls_gender" :value="d.id"
                        v-bind:key="d.code">{{d.name[$i18n.locale]}}</option>
                </b-form-select>
              </b-form-group>
            </div>
          </div>
          <b-form-group>
            <label for="txt_user_phone">{{$t('common.phone')}}</label>
            <div v-if="$v.user.phone.$error" class="pull-right">
              <div class="text-danger" v-if="!$v.user.phone.required">{{$t('common.phone_required')}}</div>
              <div class="text-danger" v-else-if="!$v.user.phone.numeric">{{$t('common.phone_number_only')}}</div>
              <div class="text-danger" v-else-if="!$v.user.phone.minLength">{{$t('common.phone_ten_number')}}</div>
              <div class="text-danger" v-else-if="!$v.user.phone.isUnique">{{$t('common.phone_existing')}}</div>
            </div>
            <b-form-input type="text" id="txt_user_phone" size="sm"
            v-model.trim="user.phone" maxlength="20" :disabled="user.id!=null"
            v-on:input="$v.user.phone.$touch"
            :placeholder="$t('common.placeholder_phone')"></b-form-input>
          </b-form-group>
          <b-form-group>
            <label for="txtEmail">{{$t('common.email')}}</label>
            <div v-if="$v.user.email.$error" class="pull-right">
              <div class="text-danger" v-if="!$v.user.email.required">{{$t('common.email_required')}}</div>
              <div class="text-danger" v-else-if="!$v.user.email.email">{{$t('common.email_invalid')}}</div>
              <div class="text-danger" v-else-if="!$v.user.email.isUnique">{{$t('common.email_existing')}}</div>
            </div>
            <b-form-input type="email" id="txtEmail" size="sm"
            v-model.trim="user.email" maxlength="50" :disabled="user.id!=null"
            v-on:input="$v.user.email.$touch"
            placeholder="Email"></b-form-input>
          </b-form-group>
          <b-form-group v-if="is_super">
            <label for="ddlcompany">{{$t('common.company')}}</label>
            <div v-if="$v.user.company_id.$error" class="pull-right">
              <div class="text-danger" v-if="!$v.user.company_id.required">{{$t('common.company_required')}}</div>
            </div>
            <b-form-select id="ddlcompany" class="form-control" v-model.trim="user.company_id" :disabled="user.id!=null"
             size="sm">
              <option value="null" disabled>{{$t('common.select_company')}}</option>
              <option v-for="d in ls_company_ddl" :value="d.id"
                  v-bind:key="d.id">{{d.name}}</option>
            </b-form-select>
          </b-form-group>
          <b-form-group>
            <label for="ddlRole">{{$t('common.role')}}</label>
            <div v-if="$v.user.role.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.user.role.required">{{$t('common.role_required')}}</div>
            </div>
            <b-form-select id="ddlRole" v-model.trim="user.role" size="sm">
                <option value="null" disabled>{{$t('common.select_role')}}</option>
                <option v-for="d in ls_role" :value="d.code"
                    v-bind:key="d.code">{{d.name[$i18n.locale]}}</option>
            </b-form-select>
          </b-form-group>
          <b-form-group v-if="is_has_son && show_branch">
            <label for="ddl_branch">{{$t('common.branch')}}</label>
            <div v-if="$v.user.branch_id.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.user.branch_id.required">{{$t('common.branch_required')}}</div>
            </div>
            <b-form-select id="ddl_branch" v-model.trim="user.branch_id" size="sm">
                <option value="null" disabled>{{$t('common.select_branch')}}</option>
                <option v-for="d in ls_branch_ddl" :value="d.id"
                    v-bind:key="d.id">{{d.name}}</option>
            </b-form-select>
          </b-form-group>
          <b-form-group>
            <label for="txt_default_password">{{$t('common.default_password')}}</label>
            <b-form-input type="text" id="txt_default_password" :placeholder="$t('common.placeholder_default_password')"
             size="sm"
            v-model.trim="user_login.default_password" maxlength="20"></b-form-input>
          </b-form-group>
          <b-form-group>
            <label for="txtAddress">{{$t('common.address')}}</label>
            <b-form-textarea type="text" id="txtAddress"
            v-model.trim="user.address" maxlength="200" size="sm"
            :placeholder="$t('common.placeholder_address')"></b-form-textarea>
          </b-form-group>
          </div>
        </div>

        <div class="row pull-right mt-2">
          <b-button variant="outline-info" size="sm"
          @click.prevent="clear()"><i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</b-button>
          <b-button class="ml-2" variant="outline-success" size="sm" @click.prevent="saveUser()"><span class="icon is-small">
              <i class="fa fa-check"></i> {{$t('common.button.save')}}</span></b-button>
        </div>
      </b-card>
    </b-col>
    <b-col class="nopadding" cols="12" xl="8">
      <transition name="slide">
      <b-card class="h-100">
        <b-row>
          <b-col lg="6" class="my-1">
            <b-form-group
              label="Sort"
              label-for="sort-by-select"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
              v-slot="{ ariaDescribedby }"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sort-by-select"
                  v-model="sortBy"
                  :options="sortOptions"
                  :aria-describedby="ariaDescribedby"
                  class="w-75"
                >
                  <template #first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>

                <b-form-select
                  v-model="sortDesc"
                  :disabled="!sortBy"
                  :aria-describedby="ariaDescribedby"
                  size="sm"
                  class="w-25"
                >
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col lg="6" class="my-1">
            <b-form-group
              label="Initial sort"
              label-for="initial-sort-select"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="initial-sort-select"
                v-model="sortDirection"
                :options="['asc', 'desc', 'last']"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="6" class="my-1">
            <b-form-group
              label="Filter"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  @keyup.enter="apply_filter"
                  :placeholder="$t('common.search_text')"
                ></b-form-input>

                <b-input-group-append>
                  <b-button @click="apply_filter">{{$t('common.search')}}</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col lg="6" class="my-1">
            <b-form-group
              v-model="sortDirection"
              label="Filter On"
              description="Leave all unchecked to filter on all data"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                v-model="filterOn"
                :aria-describedby="ariaDescribedby"
                class="mt-1"
              >
                <b-form-checkbox value="name">Name</b-form-checkbox>
                <b-form-checkbox value="age">Age</b-form-checkbox>
                <b-form-checkbox value="isActive">Active</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>

          <b-col sm="5" md="6" class="my-1">
            <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="per_page"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="7" md="6" class="my-1">
            <b-pagination
              v-model="current_page"
              :total-rows="total_row"
              :per-page="per_page"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>

        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="current_page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="company_name" slot-scope="data">
            <label>{{data.item.company_name}}</label>
          </template>
          <template slot="name" slot-scope="data">
            <label>{{data.item.name}}</label>
          </template>
          <template slot="gender" slot-scope="data">
            <label>{{get_gender(data.item.gender)}}</label>
          </template>
          <template slot="branch_name" slot-scope="data">
            <label v-if="data.item.branch_name">{{data.item.branch_name}}</label>
            <label v-else>{{data.item.company_name}}</label>
          </template>
          <template slot="phone" slot-scope="data">
            <b-badge>{{data.item.phone}}</b-badge>
          </template>
          <template slot="role" slot-scope="data">
            <b-badge>{{get_role(data.item.role)}}</b-badge>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="status" slot-scope="data">
            <!-- <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge> -->
            <b-form-select size="sm" v-model="data.item.status" :options="get_user_status()">
            </b-form-select>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)" v-b-tooltip.hover.left :title="$t('common.button.delete')">
                <i class="fa fa-trash"></i>
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)" v-b-tooltip.hover.left :title="$t('common.button.recover')">
                <i class="fa fa-recycle"></i>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-card>
      </transition>
    </b-col>
    <b-modal id="viewModal" :title="$t('common.message')" header-bg-variant="info" no-body ok-only>
      <div class="h3">{{$t('common.image_error')}}</div>
    </b-modal>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
</style>
<script>
import { validationMixin } from 'vuelidate'
import { required,requiredIf, minLength,maxLength,email,numeric }
from 'vuelidate/lib/validators'
import moment from 'moment'
import Pager from '../Pager'
import { mapGetters, mapActions } from 'vuex'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import { Role } from '../../helpers/role.js'
import { Business } from '../../helpers/business'

export default {
  name: 'Users',
  mixins: [validationMixin],
  components: {Pager},
  props: {
    hover: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      processing:false,
      ls_gender: [],
      super_admin:  { id:'super_admin',code:'super_admin',name:{"vi":"Super Admin","en":"Super Admin"}},
      admin:  { id:'admin',code:'admin',name:{"vi":'Admin',"en":"Admin"}},
      truong_chi_nhanh:  { id:'truong_chi_nhanh',code:'truong_chi_nhanh',name:{"vi":"Trưởng Chi Nhánh","en":"Branch manager"}},
      nhan_vien_kinh_doanh:  { id:'nhan_vien_kinh_doanh',code:'nhan_vien_kinh_doanh',name:{"vi":"Nhân viên kinh doanh","en":"Business man"}},
      nhan_vien_giao_hang:  { id:'nhan_vien_giao_hang',code:'nhan_vien_giao_hang',name:{"vi":"Nhân viên giao hàng","en":"Delivery"}},
      quan_ly_kho:  { id:'quan_ly_kho',code:'quan_ly_kho',name:{"vi":"Quản lý kho","en":"Store manager"}},
      sub_admin:  { id:'sub_admin',code:'sub_admin',name:{"en":"Sub Admin","vi":"Sub Admin"}},
      truong_chi_nhanh:  { id:'truong_chi_nhanh',code:'truong_chi_nhanh',name:{"vi":"Trưởng chi nhánh","en":"Branch Admin"}},
      normal_user:  { id:'user',code:'user',name:{"en":"User","vi":"Nhân viên"}},
      ky_thuat_vien:  { id:'ky_thuat_vien',code:'ky_thuat_vien',name:{"en":"Technical service","vi":"Kỹ thuật viên"}},
      cham_soc_khach_hang:  { id:'cham_soc_khach_hang',code:'cham_soc_khach_hang',name:{"en":"Customer service","vi":"Nhân viên chăm sóc khách hàng"}},
      option_status:[],
      user:{
        id:null,
        password:'hansanvn',
        confirm_password:'hansanvn',
        name:null,
        code:moment().valueOf().toString(),
        gender:null,
        phone:null,
        email:null,
        user_create_id:null,
        user_update_id:null,
        company_id:null,
        branch_id:null,
        address:null,
        role:null,
        logo:null,
        logo_url:null
      },
      filters: {
        id: '',
        issuedBy: '',
        issuedTo: ''
      },
      totalRows: 1,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      }
    }
  },
  validations: {
    user: {
      name: {
        required,
        maxLength:maxLength(50)
      },
      gender:{
        required
      },
      phone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength:maxLength(15),
        async isUnique(value) {
          if (value==null) return true
          if(this.user && this.user.id!=null) return true
          const res = await this.checkPhoneUnique(value)
          return Boolean(res.data)
        }
      },
      email: {
        required,
        email,
        maxLength:maxLength(50),
        async isUnique(value) {
          if (value==null) return true
          if(this.user && this.user.id!=null) return true

          const res = await this.checkEmailUnique(value)
          return Boolean(res.data)
        }
      },
      branch_id:{
        required: requiredIf(function () {
          return this.is_has_son && (this.is_super||this.is_admin) && this.user.role!=null && (this.user.role==Role.Truong_room||this.user.role==Role.Branch_Admin||this.user.role==Role.User)
        })
      },
      company_id:{
        required: requiredIf(function () {
          return this.is_super
        })
      },
      role:{
        required
      }
    }
  },
  computed: {
    ...mapGetters('st_user', ['ls_user','total_row','current_page','filter','per_page']),
    fields(){
      let fields=[]
    
      let user=this.$store.state.st_authentication.user
      if(user.role==Role.Super_Admin){
        fields.push({key: 'company_name',label:this.$t('common.company'),sortable: true, variant:'info'})
      }else if(user.role==Role.Admin){
        fields.push({key: 'branch_name',label:this.$t('common.company_branch'),sortable: true, variant:'info'})
      }

      fields.push({key: 'name',label:this.$t('common.fullname'),sortable: true, variant:'primary'})
      fields.push({key: 'email',label:this.$t('common.email'),sortable: true})
      fields.push({key: 'phone',label:this.$t('common.phone'),sortable: true})
      fields.push({key: 'gender',label:this.$t('common.gender'),sortable: true})
      fields.push({key: 'role',label:this.$t('common.role'),sortable: true})
      fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
      fields.push({key: 'status',label:this.$t('common.status'),sortable: true})

      if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
        fields.push({key: 'action',label:'',sortable: false})
      }

      return fields
    },
    show_branch(){
      debugger
      if(this.user_login==null) return
      if(this.user_login.role==null) return false
      return (this.user_login.role==Role.Admin) && this.user.role!=null && (this.user.role==Role.Truong_room||this.user.role==Role.Branch_Admin||this.user.role==Role.User)
    },
    business() {
      let user=this.$store.state.st_authentication.user
      if(user==null) return
      return user.company.business
    },
    is_super() {
      let user=this.$store.state.st_authentication.user
      return user&&user.role==Role.Super_Admin
    },
    is_admin() {
      let user=this.$store.state.st_authentication.user
      return user&&user.role==Role.Admin
    },
    is_branch() {
      let user=this.$store.state.st_authentication.user
      return user&&(user.role==Role.Truong_room || user.role==Role.Branch_Admin)
    },
    user_login() {
      return this.$store.state.st_authentication.user
    },
    is_has_son() {
      let user=this.$store.state.st_authentication.user
      return user&&user.company_id&&user.company.cocon
    },
    ls_role(){
      let user=this.$store.state.st_authentication.user
      if(!user) return
      debugger
      if (user.role==Role.Super_Admin) {
        return [this.super_admin,this.admin,this.truong_chi_nhanh,this.super_admin,this.quan_ly_kho,this.nhan_vien_kinh_doanh,this.nhan_vien_giao_hang,this.normal_user]
      }

      if(user.company.business==Business.oto){
        if(user.company.cocon){
          return [this.truong_chi_nhanh,this.quan_ly_kho,this.ky_thuat_vien,this.cham_soc_khach_hang]
        }

        return [this.quan_ly_kho,this.ky_thuat_vien,this.cham_soc_khach_hang]
      }
      else
      {
        if(user.company.business==Business.vlxd||user.company.business==Business.tole
        ||user.company.business==Business.water){
          if(user.company.cocon){
            return [this.truong_chi_nhanh,this.quan_ly_kho,this.nhan_vien_kinh_doanh,this.nhan_vien_giao_hang]
          }

          return [this.quan_ly_kho,this.nhan_vien_kinh_doanh,this.nhan_vien_giao_hang]
        }
        else
        {
          if (user.role==Role.Admin) {
            if(user.company.cocon){
              return [this.admin,this.truong_chi_nhanh,this.sub_admin,this.normal_user]
            }

            return [this.admin,this.sub_admin]
          }else{
            return [this.truong_chi_nhanh,this.sub_admin,this.normal_user]
          }
        }
      }
    },
    ls_branch_ddl(){
      return this.$store.state.st_branch.ls_branch_ddl
    },
    ls_company_ddl(){
      return this.$store.state.st_company.ls_company_ddl
    },
    filter:{
      get: function(){
        return this.$store.state.st_user.filter
      },
      set: function(text){
        this.$store.commit('st_user/set_filter',text)
      }
    },
    per_page:{
      get: function(){
        return this.$store.state.st_user.per_page
      },
      set: function(perpage){
        this.$store.commit('st_user/set_per_page',perpage)
      }
    },
    filteredAndSortedData:{//0944478316
      get: function(){
        let vm=this
        let result = vm.$store.state.st_user.ls_user
        debugger

        if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
        }

        return result
      }
    }
  },
  methods: {
    ...mapActions('st_user', ['get_ls_user','change_page', 'apply_filter']),
    clear(){
      debugger
      this.user={id:null,password:this.user_login.default_password,confirm_password:this.user_login.default_password
      ,gender:null,role:null,company_id:null,logo:null,logo_url:null,code:moment().valueOf().toString()}
      if(this.is_branch){
        this.user.role=null
      }
      this.$refs.txt_name.$el.focus()
      this.$v.$reset()
    },
    get_role(role){
      let temp=this.ls_role.find(x=>x.code==role)
      if(temp){
        return temp.name[this.$i18n.locale]
      }

      return ""
    },
    rowDeleted(item, type){
      if (!item || type !== 'row') return
      if (item.status === -1) return 'table-danger'
    },
    async checkPhoneUnique(phone){
      debugger
      return await this.$store.dispatch(`st_user/phone_unique`,{phone:phone,company_id:this.user_login.company_id,branch_id:this.user_login.branch_id})
    },
    async checkEmailUnique(email){
      return await this.$store.dispatch(`st_user/email_unique`,email)
    },
    ge_ls_branch_ddl(company_id){
      this.$store.dispatch('st_branch/get_ls_branch_ddl',company_id)
    },
    ge_ls_company_ddl(company_id){
      this.$store.dispatch('st_company/get_ls_company_ddl',company_id)
    },
    async recoverObj(obj){
      let app=this
      if(obj.status>=0) return
      let co=await this.$store.dispatch('st_user/recover_obj',obj.id)
      if(co && co.data.ok){
        this.get_ls_user()
        app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    confirmDelete(obj) {
      this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
        .then(value => {
          if(value!=true) return
          this.deleteObj(obj)
        })
        .catch(err => {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
    },
    async deleteObj(obj) {
      let app=this
      debugger
      if(obj.status<0) return
      if(obj.status==1){
        app.$toastr.info(app.$t("common.item_using_message",{obj:this.$t("common.user")}),app.$t("common.message"))
        return
      }
      let co=await this.$store.dispatch('st_user/delete_obj',obj.id)
      if(co && co.data.ok){
        this.get_ls_user()
        app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    _validations () {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
            return resolve()
        }
        let poll = setInterval(() => {
        if (!this.$v.$pending) {
            clearInterval(poll)
            resolve()
        }
        }, 200)
      })
    },
    async isValid () {
      this.$v.$reset()
      this.$v.$touch()
      await this._validations()
      return Promise.resolve(!this.$v.$error)
    },
    async saveUser() {
      const isValid = await this.isValid()
      debugger
      if (!isValid) {
        return
      }

      var app = this
      if (app.processing === true) {
        return
      }
      app.processing = true

      if(app.user.id){
        app.user_update_id=app.user_login.id
        app.$store.dispatch('st_user/update',app.user)
        .then(function (resp) {
          app.get_ls_user()
          app.clear()
          app.processing = false
          app.$toastr.success(app.$t("common.updated_success"),app.$t("common.message"))
        })
        .catch(function (resp) {
          app.processing = false
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      }else{
        debugger
        app.user.user_create_id=app.user_login.id
        if(!app.is_super){
          app.user.company_id=app.user_login.company_id
          app.user.password=app.user_login.default_password
          app.user.confirm_password=app.user_login.default_password
          // app.user.branch_id=app.user_login.branch_id
        }

        app.$store.dispatch('st_user/create',app.user)
        .then(function (resp) {
          app.get_ls_user()
          app.clear()
          app.processing = false
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
        })
        .catch(function (resp) {
          app.processing = false
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      }
    },
    getRowCount (items) {
      return items.length
    },
    rowClicked (item) {
      debugger
      this.user=item
    },
    searchLike:function(item){
      debugger
      return item.name.includes(this.filter)
      ||item.code.includes(this.filter)
      ||item.created_at.includes(this.filter)
      ||item.address&&item.address.includes(this.filter)
      ||item.gender&&item.gender.includes(this.filter)
      ||item.branch&&item.branch.name.includes(this.filter)
      ||item.email.includes(this.filter)
      ||item.phone.includes(this.filter)
      ||item.role.includes(this.filter)
      ||item.company_name.includes(this.filter)
    },
    getImage:function(e){
      let app=this
      let image=e.target.files[0]
      if(image.size>102400){
        app.$bvModal.show("viewModal")
        document.getElementById("fileControl").value = ""
        return
      }
      let reader=new FileReader()
      reader.readAsDataURL(image)
      reader.onload=e=>{
        app.user.logo=e.target.result
      }
    }
  },
  mounted () {
    let vm=this
    debugger
    vm.ls_gender=vm.option_gender
    vm.$refs.txt_name.$el.focus()
    vm.get_ls_user()
    vm.ge_ls_branch_ddl(vm.user_login.company_id)
    vm.ge_ls_company_ddl(vm.user_login.company_id)

    if(vm.is_branch){
      vm.user.role=Role.user
    }
  }
}
</script>
